<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>

            <h3>Formato del file <u>con</u> riga di intestazione e virgola "," separatore di campo</h3>
            <Button type="button" label="Nome" class="mr-2" />
            <Button type="button" label="Cognome" class="mr-2" />
            <Button type="button" label="Email" class="mr-2" />
            <Button type="button" label="Extra ID" class="mr-2" />
            <Button type="button" label="Prefix" class="mr-2" title='Identifica tutti gli acquisti di questo file'/>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyCsv'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='file'
                                   type='text'
                                   v-model="model.file"
                        />
                        <label for='file'>CSV</label>
                    </span>
                    <div v-if='model.file' class='text-center'>
                        <a :href='model.file'>Preview</a>
                    </div>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Tasks/ImportPurchases');
import useVuelidate from '@vuelidate/core';

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

export default {
    components: {},
    data() {
        return {};
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    mounted() {
        this.model = this.row;
        this.prepareUploads(model.uploads, this.lang);
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n) {
            this.model = n;
        },
    },
    methods: {
        ...mapActions(['savePlain', 'createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save() {
            const result = await this.v$.$validate();
            if (!result) {
                this.ShowError('Errore', 'Alcuni campi del form non sono corretti');
                return;
            }

            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then(() => {
                this.formIsClean();
                if (changeRoute) {
                    this.navigateToBase().then(() => {
                        this.ShowSuccess('Ottimo', 'Task creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Task modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
        Uploader
    ],
};
</script>
